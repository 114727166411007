import { createTheme, loadTheme } from "@fluentui/react";

const appTheme = createTheme({
  palette: {
    themePrimary: '#e12826',
    themeLighterAlt: '#fef5f5',
    themeLighter: '#fad9d9',
    themeLight: '#f6bab9',
    themeTertiary: '#ed7977',
    themeSecondary: '#e4403d',
    themeDarkAlt: '#ca2522',
    themeDark: '#ab1f1d',
    themeDarker: '#7e1715',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralSecondaryAlt: '#8a8886',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
  }
});
loadTheme(appTheme);

export const Test = () => {
    
}