import React from 'react';

const locales = {
	en: {
		Active: 'Active',
		ActiveSubscriptions: "Active subscriptions",
		Add: 'Add',
		AddOrRemoveTenants: 'Add or remove tenants for selected partner to manage',
		AddOrRemovePartnerUsers: 'Add or remove users for selected partner',
		AddPartner: 'Add Partner',
		AddSubscription: 'Add Subscription',
		AddTenant: 'Add tenant',
		AdminConsent: "Admin consent",
		AdminUsers: 'Admin users',
		AdvancedSearchSettings: 'Advanced search settings',
		AdvancedSiteSettings: 'Advanced site settings',
		AllowOpenInSharePoint: 'Allow open in SharePoint',
		Amount: 'Amount',
		ApiKey: 'API key',
		ApprovalStatus: 'Approval status',
		Approved: 'Approved',
		AppLogo: 'App logo',
		AppColor: 'App color',
		AppTitle: 'App title',
		AssignedToAnother: 'Tenant assigned to another partner',
		AssignedToYou: 'Tenant is already assigned to you',
		AssignAllLicenses: 'Assign all licenses',
		Attachments: 'Attachments',
		AuthFailed: 'The sign in request could not be completed.',
		AuthKey: 'Authentication key',
		AuthProcessing: 'Processing sign-in request ...',
		AuthSecret: 'Autentication secret',
		AuthToken: 'Autentication token',
		AutoDeleteMinorVersionsOnPublish: 'Auto delete minor versions on publish',
		AutoFolderCreation: 'Auto folder creation',
		AutoSelectAttachments: 'Auto select attachments',
		AutoRemoveMailItemAttachments: 'Remove attachments from mail item on save',
		Available: 'Available',
		AvailableLicences: 'Available licences',
		AssignedLicences: 'Assigned licences',
		Bcc: 'Bcc',
		Cancel: 'Cancel',
		Canceled: 'Canceled',
		Categories: 'Categories',
		Cc: 'Cc',
		Change: 'Change',
		ClearFilter: "Clear filter",
		ClearSearch: "Clear search",
		ClearSelection: "Clear selection",
		ClickForCalendar: 'Click for Calendar',
		Configure: 'Configure',
		Confirm: 'Confirm',
		ConsentSuccessful: "Consent successfully granted.",
		ConsentToApprovalPermissions: "Power Automate",
		ConsentToSharePointPermissions: "SharePoint",
		ConsentToUserPermissions: "User",
		ContentType: 'Content Type',
		ConversationIndex: 'Conversation-index',
		ConversationTopic: 'Conversation-topic',
		ConvertEmailToPdf: 'Convert email to pdf on save',
		Create: 'Create',
		Created: 'Created',
		DataSourceDescription: 'Manage your tenant data sources. Data sources can be set up to create a custom mapping between a data source field, and a SharePoint meta data column.',
		DataSourceFieldName: 'Datasource field name',
		DataSourceFieldNameDuplicate: 'Datasource field name already exists',
		DataSourceName: 'Data source name',
		DatasourceNumber: '{number} datasources connected',
		DataSource: 'Data source',
		DataSources: 'Data sources',
		DataSourceSettings: 'Data source settings',
		Date: 'Date',
		DateRegistered: 'Date registered',
		Deactivated: 'Deactivated',
		Default: 'Default',
		DefaultLicenseType: 'Default user license',
		Delete: 'Delete',
		DeleteWarningMessageDataSource: 'Are you sure you want to delete this datasource?',
		DeleteWarningMessageUsers: 'Are you sure you want to delete the selected user(s)?',
		Description: 'Description',
		Disabled: 'Disabled',
		DisableDocumentRename: 'Disable renaming of documents',
		Discard: 'Discard',
		DisplayAsDropDown: 'Display as dropdown',
		DisplayName: 'Display name',
		DocubirdSubscriptionReport: 'XA dms subscription report',
		DocumentLibraries: 'Document libraries',
		DocumentSet: 'Document Set',
		DocumentSettings: 'Document settings',
		Done: 'Done',
		DuplicatesFound: 'Duplicate entries found:',
		DuplicateSitesFound: 'Duplicate site names found:',
		Edit: 'Edit',
		EditDatasource: 'Edit data source',
		EditDatasourceDescription: 'Add, delete, or edit the fields for this data source.\nThe fields shown here show the mapping between a data source value and a SharePoint metadata field.',
		EditDetails: 'Edit details',
		Empty: 'Empty',
		EmailHeaderMapping: 'Email headers mapping',
		Enable: 'Enable',
		Enabled: 'Enabled',
		EnableApprovals: 'Enable approvals center',
		EnableCopyMoveTo: 'Enable copy/move to',
		EnablePermissionManagent: 'Enable management of site/library permissions',
		EnablePII: 'Enable PII',
		EnablePIIRedaction: 'Enable redaction',
		EnableNewTemplateFilters: 'Enable new template filters',
		EnableSharing: 'Enable sharing',
		EnterAuthKey: 'Enter authentication key',
		EnterAuthSecret: 'Enter authentication secret',
		EnterNewLibName: 'Enter a new library name',
		EnterNewSiteName: 'Enter a new site name',
		EnterSharepointColumn: 'Enter SharePoint column name',
		EnterUrl: 'Enter URL',
		Error: 'Error',
		ErrorAddingDatasourceMappedField: 'Error adding mapped field. Please try again.',
		Evaluation: 'Evaluation',
		ExchangeSettings: 'Exchange settings',
		Expired: 'Expired',
		ExpiryDate: 'Expiry date',
		ExportToExcel: 'Export to Excel',
		Field: 'field',
		Fields: 'fields',
		FieldName: 'Field name',
		FieldToDisplay: 'Enter field to display',
		FieldShown: '{number} {fieldOrSite} shown',
		FieldsShown: '{number} {fieldOrSite} shown',
		FieldHidden: '{number} {fieldOrSite} hidden',
		FieldsHidden: '{number} {fieldOrSite} hidden',
		File: 'File',
		Filter: 'Filter',
		FilterDateStepSince: "Since",
		FilterDateStepLast3Months: "In the last 3 months",
		FilterDateStepLast30Days: "In last 30 days",
		FilterDateStepLast7Days: "In last 7 days",
		FilterDateStepSinceYesterday: "Since yesterday",
		FilterDateStepToday: "Today",
		FilterDateStepTomorrow: "Tomorrow",
		FilterDateStepIn7Days: "In 7 days",
		FilterDateStepIn30Days: "In 30 days",
		FilterDateStepIn3Months: "In 3 months",
		FilterDateStepInAYearOrMore: "In a year or more",
		FilterUsers: 'Filter users',
		FilterTenants: 'Filter tenants',
		Folder: 'Folder',
		Free: 'Free',
		Frequency: 'Frequency',
		FriendlyName: 'Friendly name',
		From: 'From',
		Hidden: 'Hidden',
		HideAutoPreview: 'Hide auto file preview',
		HideDocumentLibrariesInTeams: "Hide Teams sites document libraries",
		HideTeamsSitesInSharePoint: "Hide Teams sites under SharePoint",
		Importance: 'Importance',
		Inactive: 'Inactive',
		InfoTypesSelected: 'info types selected',
		InReplyTo: 'In reply to',
		InsideDocumentLibrary: 'Inside document library',
		InsideFolder: 'Inside folder',
		InsideDocumentSet: 'Inside document set',
		Invoice: 'Invoice',
		InvoiceStatic: 'Invoice static',
		InvoiceDynamic: 'Invoice dynamic',
		Manage: 'Manage',
		ManageAdvancedSearchFields: 'Manage advanced search fields to display',
		ManageDatasources: 'Manage tenant data sources',
		ManageTeamsFields: 'Manage teams to display',
		ManageSites: 'Manage sites to display',
		ManageSitesPanelInfo: 'Add, remove, or edit tenant sites',
		ManageLibraries: 'Manage libraries',
		ManageMetaData: 'Manage metadata fields to hide',
		ManagedTenants: 'Managed tenants',
		ManageSubscription: 'Manage subscription',
		MappedFields: 'Mapped fields',
		MessageId: 'Message-ID',
		MetadataColorsEdit: "Edit metadata colours",
		MetaDataColorPicker: "Metadata Color Picker",
		MetadataColorValues: "metadata colour value(s)",
		MetaDataSettings: 'Metadata settings',
		MetaDataValue: "Metadata Value",
		Method: "Method",
		MinimumVersionLimit: 'Minimum version limit',
		MinimumLicenseCharge: "A minimum of {amount} licenses will be charged",
		Monthly: 'Monthly',
		Multiselect: 'Multiselect',
		Name: 'Name',
		New: 'New',
		NewTemplateSettings: 'New template settings',
		None: 'None',
		No: 'No',
		NoAcceptedRequests: 'No accepted requests',
		NoEmptyFields: 'Fill in empty fields',
		NoFiltersAvailable: "No filters available",
		NoOptionsAvailable: 'No options available',
		NoPartner: 'No partner assigned',
		NoPendingRequests: 'No pending requests',
		NoRejectedRequests: 'No rejected requests',
		NoRoleAssigned: 'No role assigned',
		NoTenantAdmins: 'No tenant admins',
		NoTenants: 'No managed tenants',
		NoPartnerUsers: 'No partner users',
		NoSubscriptionsFound: "No subscriptions found",
		NoTenantSelected: 'No tenant selected',
		NumberOfUsers: 'Number of users',
		OcrSettings: 'OCR settings',
		OcrLanguage: 'OCR language',
		Ok: 'Ok',
		OnceOff: 'Once off',
		OpenDocumentsInClientApp: 'Open supporting documents in client application',
		OriginalSubject: 'Original subject',
		OutlookSettings: 'Outlook settings',
		Overview: 'Overview',
		OverwriteUserEmailMappings: "Do you want to overwrite all user email mappings?",
		OverwriteUsers: "Overwrite users",
		LandingPage: "Landing page",
		LastActivity: 'Last activity',
		LastActivityNone: '-',
		LibraryPanelSubtitle: 'Add, remove, or edit site libraries',
		LicenseExpiryDate: 'License expiry date',
		LicenseType: 'License type',
		LicenseCount: 'License count',
		ListName: 'List name',
		LoadingPortal: 'Loading XA dms portal...',
		LoadingFilters: "Loading filters",
		Locations: 'Add-in settings',
		Paid: 'Paid',
		Payments: 'Payments',
		PaymentMethod: 'Payment method',
		PaymentStatus: 'Payment status',
		Partner: 'Partner',
		Partners: 'Partners',
		PartnerUsers: 'Partner users',
		Pending: 'Pending',
		PermissionSettings: 'Permission settings',
		PIISettings: 'PII settings',
		PickColor: 'Pick color',
		Profile: 'Profile',
		PricePerLicenseMonth: "Monthly - €{price}/user/month",
		PricePerLicenseYear: "Annually - €{price}/user/month",
		Received: 'Received',
		References: 'References',
		Regenerate: 'Regenerate',
		RegenerateApiKeyWarning: 'Are you sure you want to generate a new API key? This action is irreversible.',
		Remove: 'Remove',
		Rejected: 'Rejected',
		ReplyTo: 'Reply to',
		RequiredMetadataOnPublish: 'Required metadata on publish',
		Requests: 'Requests',
		RequestDate: 'Request date',
		RequestTenant: 'Request tenant',
		RequestingPartner: 'Requesting partner',
		Reset: "Reset",
		RestrictToLibraries: 'Restrict to library/list(s) eg. list1; list2',
		RestrictToSites: 'Restrict to site(s) eg. site1; site2',
		Resume: 'Resume',
		RevokeLicenses: 'Revoke licenses',
		Role: 'Role',
		Save: 'Save',
		SaveChanges: 'Save changes',
		SaveOnSend: 'Save on send',
		SaveRequest: 'Save request',
		Search: 'Search',
		SearchSectionFilter: "Search {section} filters",
		SelectDatasourceField: 'Select datasource field',
		SelectDatasourceType: 'Select a data source type',
		SelectPartner: 'Select a partner to manage this tenant',
		SelectTenantAdmin: 'Select tenant admins',
		SelectTenant: 'Select tenant',
		SetAsPartner: 'Set user as partner',
		SelectPiiInfoTypes: 'Select PII info types',
		SelectSpecificDates: "Select specific dates",
		SelectSubscription: "Select subscription",
		SelectPaymentMethod: 'Select payment method',
		SetAsTenantAdmin: 'Set user as tenant admin',
		Show: 'Show',
		ShowDateRangeSlider: "Show date range slider",
		ShowLess: "Show less",
		ShowMore: "Show more",
		ShowAllFields: 'Show all fields',
		ShowAllTeams: 'Show all teams',
		ShowAllSites: 'Show all sites',
		ShowAllMetadata: 'Show all metadata fields',
		ShowAllTemplates: 'Show all templates',
		ShowAll: 'Show all',
		ShowSelected: 'Show selected',
		SignInDifferentAccount: 'Sign in with a different account',
		SignInRequest: 'Processing sign-in request...',
		SignedOutMessage: 'You have successfully signed out of the XA dms portal application.',
		SignOut: 'Sign out',
		Site: 'site',
		Sites: 'sites',
		SitesLibrariesConfigured: '{siteCount} sites, {libraryCount} libraries configured',
		SiteName: 'Site name',
		SiteSettings: 'Site settings',
		SiteUrl: 'Site URL',
		SharepointColumnName: 'SharePoint column name(s) eg. col1, col2',
		Status: 'Status',
		Subject: 'Subject',
		Subscription: 'Subscription',
		Subscriptions: 'Subscriptions',
		SubscriptionTier: 'Subscription tier',
		SubscriptionType: 'Subscription type',
		Success: 'Success',
		Suspend: 'Suspend',
		TeamName: 'Team name',
		TeamSettings: 'Teams settings',
		Tenant: 'Tenant',
		Tenants: 'Tenants',
		TenantAdmin: 'Tenant admin',
		TenantId: 'Tenant ID',
		TenantRequestSuccess: 'Successfully requested to manage a tenant',
		Title: 'Title',
		TitleRequired: 'Title required',
		To: 'To',
		TotalRegistrations: 'Total registrations',
		TryAgain: 'Try again',
		Type: 'Type',
		Unassigned: "Unassigned",
		UnauthorizedMessage: 'You are not authorized to access the XA dms portal application.',
		Url: 'URL',
		User: 'User',
		UserActivity: 'User activity',
		Username: 'Username',
		Users: 'Users',
		VersionSettings: 'Version settings',
		Yearly: 'Yearly',
		Yes: 'Yes',
	},
	nl: {
		Active: 'Actief',
		ActiveSubscriptions: "Actieve abonnementen",
		Add: 'Toevoegen',
		AddOrRemoveTenants: 'Tenants toevoegen of verwijderen voor geselecteerde partner om te beheren',
		AddOrRemovePartnerUsers: 'Gebruikers toevoegen of verwijderen voor geselecteerde partner',
		AddPartner: 'Partner toevoegen',
		AddSubscription: 'Abonnement toevoegen',
		AddTenant: 'Tenant toevoegen',
		AdminConsent: "Beheerderstoestemming",
		AdminUsers: 'Admin gebruikers',
		AdvancedSearchSettings: 'Geavanceerde zoekinstellingen',
		AdvancedSiteSettings: 'Geavanceerde site-instellingen',
		AllowOpenInSharePoint: 'Openen in SharePoint toestaan',
		Amount: 'Bedrag',
		ApiKey: 'API sleutel',
		ApprovalStatus: 'Goedkeuringsstatus',
		Approved: 'Goedgekeurd',
		AppLogo: 'App logo',
		AppColor: 'App kleur',
		AppTitle: 'App titel',
		AssignedToAnother: 'Tenant toegewezen aan een andere partner',
		AssignedToYou: 'Tenant is al aan u toegewezen',
		AssignAllLicenses: 'Alle licenties toewijzen',
		Attachments: 'Bijlagen',
		AuthFailed: 'Het aanmeldingsverzoek kan niet worden voltooid.',
		AuthKey: 'Verificatiesleutel',
		AuthProcessing: 'Aanmeldingsverzoek verwerken ...',
		AuthSecret: 'Authenticatiegeheim',
		AuthToken: 'Autenticatietoken',
		AutoDeleteMinorVersionsOnPublish: 'Kleine versies automatisch verwijderen bij publicatie',
		AutoFolderCreation: 'Automatische map',
		AutoSelectAttachments: 'Bijlagen automatisch selecteren',
		AutoRemoveMailItemAttachments: 'Verwijder bijlagen van e-mailitem bij opslaan',
		Available: 'Beschikbaar',
		AvailableLicences: 'Beschikbare licenties',
		AssignedLicences: 'Toegewezen licenties',
		Bcc: 'Bcc',
		Cancel: 'Annuleer',
		Canceled: 'Geannuleerd',
		Categories: 'Categorieën',
		Cc: 'Cc',
		Change: 'Verander',
		ClearFilter: "Filter wissen",
		ClearSearch: "Zoekactie verwijderen",
		ClearSelection: "Selectie wissen",
		ClickForCalendar: 'Klik voor kalender',
		Configure: 'Configureer',
		Confirm: 'Bevestig',
		ConsentSuccessful: "Toestemming succesvol verleend.",
		ConsentToApprovalPermissions: "Power Automate",
		ConsentToSharePointPermissions: "SharePoint",
		ConsentToUserPermissions: "Gebruiker",
		ContentType: 'Inhoudstype',
		ConversationIndex: 'Conversatie-index',
		ConversationTopic: 'Conversatie-onderwerp',
		ConvertEmailToPdf: 'E-mail converteren naar pdf bij opslaan',
		Create: 'Aanmaken',
		Created: 'Aangemaakt',
		DataSourceDescription: 'Beheer uw gegevensbronnen voor huurders. Gegevensbronnen kunnen worden ingesteld om een ​​aangepaste toewijzing te maken tussen een gegevensbronveld en een SharePoint-metagegevenskolom.',
		DataSourceFieldName: 'Gegevensbron veldnaam',
		DataSourceFieldNameDuplicate: 'De naam van het gegevensbronveld bestaat al',
		DataSourceName: 'Naam gegevensbron',
		DatasourceNumber: '{number} databronnen verbonden',
		DataSource: 'Data bron',
		DataSources: 'Data bronnen',
		DocumentSet: 'Documentenset',
		DataSourceSettings: 'Instellingen gegevensbron',
		Date: 'Datum',
		DateRegistered: 'Datum geregistreerd',
		Deactivated: 'Gedeactiveerd',
		Default: 'Standaard',
		DefaultLicenseType: 'Standaard gebruiker licentie',
		Delete: 'Verwijderen',
		DeleteWarningMessageDataSource: 'Weet u zeker dat u deze gegevensbron wilt verwijderen?',
		DeleteWarningMessageUsers: 'Weet je zeker dat je de geselecteerde gebruiker(s) wilt verwijderen?',
		Description: 'Beschrijving',
		Disabled: 'Uitgeschakeld',
		DisableDocumentRename: 'Hernoemen van documenten uitschakelen',
		Discard: 'Afleggen',
		DisplayAsDropDown: 'Weergeven als vervolgkeuzelijst',
		DisplayName: 'Weergavenaam',
		DocubirdSubscriptionReport: 'XA dms abonnementsverslag',
		DocumentLibraries: 'Documentbibliotheken',
		DocumentSettings: 'Document-instellingen',
		Done: 'Klaar',
		DuplicatesFound: 'Dubbele vermeldingen gevonden:',
		DuplicateSitesFound: 'Dubbele sitenamen gevonden:',
		Edit: 'Bewerk',
		EditDatasource: 'Gegevensbron bewerken',
		EditDatasourceDescription: 'Voeg de velden voor deze gegevensbron toe, verwijder of bewerk deze.\nDe velden die hier worden weergegeven, tonen de toewijzing tussen een gegevensbronwaarde en een SharePoint-metagegevensveld.',
		EditDetails: 'Details bewerken',
		Empty: 'Leeg',
		EmailHeaderMapping: 'Toewijzing van e-mailheaders',
		Enable: 'Inschakelen',
		Enabled: 'Ingeschakeld',
		EnableApprovals: 'Goedkeuringscentrum inschakelen',
		EnableCopyMoveTo: 'Inschakelen kopiëren/verplaatsen naar',
		EnablePermissionManagent: 'Beheer van site/bibliotheek-rechten inschakelen',
		EnablePII: 'PII inschakelen',
		EnablePIIRedaction: 'Redactie inschakelen',
		EnableNewTemplateFilters: 'Nieuwe sjabloonfilters inschakelen',
		EnableSharing: 'Delen mogelijk maken',
		EnterAuthKey: 'Verificatiesleutel invoeren',
		EnterAuthSecret: 'Voer authenticatiegeheim in',
		EnterNewLibName: 'Voer een nieuwe bibliotheeknaam in',
		EnterNewSiteName: 'Voer een nieuwe sitenaam in',
		EnterSharepointColumn: 'Voer de naam van de SharePoint-kolom in',
		EnterUrl: 'Voer URL in',
		Error: 'Fout',
		ErrorAddingDatasourceMappedField: 'Fout bij het toevoegen van gemapt veld. Probeer het opnieuw.',
		Evaluation: 'Evaluatie',
		ExchangeSettings: 'Exchange-instellingen',
		Expired: 'Vervallen',
		ExpiryDate: 'Vervaldatum',
		ExportToExcel: 'Exporteren naar Excel',
		Field: 'veld',
		Fields: 'velden',
		FieldName: 'Veldnaam',
		FieldToDisplay: 'Veld om weer te geven',
		FieldShown: '{number} {fieldOrSite} getoond',
		FieldsShown: '{number} {fieldOrSite} getoond',
		FieldHidden: '{number} {fieldOrSite} verborgen',
		FieldsHidden: '{number} {fieldOrSite} verborgen',
		File: 'Bestand',
		Filter: 'Filter',
		FilterDateStepSince: "Sinds",
		FilterDateStepLast3Months: "In de afgelopen 3 maanden",
		FilterDateStepLast30Days: "In de afgelopen 30 dagen",
		FilterDateStepLast7Days: "In de afgelopen 7 dagen",
		FilterDateStepSinceYesterday: "Sinds gisteren",
		FilterDateStepToday: "Vandaag",
		FilterDateStepTomorrow: "Morgen",
		FilterDateStepIn7Days: "Over 7 dagen",
		FilterDateStepIn30Days: "Over 30 dagen",
		FilterDateStepIn3Months: "Over 3 maanden",
		FilterDateStepInAYearOrMore: "Over een jaar of langer",
		FilterUsers: 'Gebruikers filteren',
		FilterTenants: 'tenants filteren',
		Folder: 'Map',
		Free: 'Gratis',
		Frequency: 'Frequentie',
		FriendlyName: 'Vriendelijke naam',
		From: 'Van',
		Hidden: 'Verborgen',
		HideAutoPreview: 'Verberg automatisch bestandsvoorbeelden',
		HideDocumentLibrariesInTeams: "Verberg teamsites documentbibliotheken",
		HideTeamsSitesInSharePoint: "Verberg Teams-sites onder SharePoint",
		Importance: 'Belangrijkheid',
		Inactive: 'Inactief',
		InfoTypesSelected: 'infotypes geselecteerd',
		InReplyTo: 'Als antwoord op',
		InsideDocumentLibrary: 'In de documentenbibliotheek',
		InsideFolder: 'Binnenkant map',
		InsideDocumentSet: 'Binnenkant documentenset',
		Invoice: 'Factuur',
		InvoiceStatic: 'Statische factuur',
		InvoiceDynamic: 'Dynamische factuur',
		Manage: 'Beheren',
		ManageAdvancedSearchFields: 'Geavanceerde zoekvelden om weer te geven',
		ManageDatasources: 'Gegevensbronnen van huurders beheren',
		ManageTeamsFields: 'Beheer teams om weer te geven',
		ManageSites: 'Sites beheren om weer te geven',
		ManageSitesPanelInfo: 'Sites toevoegen, verwijderen of bewerken',
		ManageLibraries: 'Bibliotheken beheren',
		ManageMetaData: 'Metadatavelden beheren om te verbergen',
		ManagedTenants: 'Beheerde tenants',
		ManageSubscription: 'Abonnement beheren',
		MappedFields: 'Toegewezen velden',
		MessageId: 'Bericht-ID',
		MetadataColorsEdit: "Metagegevenskleuren bewerken",
		MetaDataColorPicker: "Kleurkiezer voor metagegevens",
		MetadataColorValues: "metadata-kleurwaarde(n)",
		MetaDataSettings: 'Metadata-instellingen',
		MetaDataValue: "Metagegevenswaarde",
		Method: "Methode",
		MinimumVersionLimit: 'Minimale versielimiet',
		MinimumLicenseCharge: "Een minimum van {amount} licenties wordt in rekening gebracht",
		Monthly: 'Maandelijks',
		Multiselect: 'Meerkeuze selectie',
		Name: 'Naam',
		New: 'Nieuwe',
		NewTemplateSettings: 'Nieuwe sjablooninstellingen',
		None: 'Geen',
		No: 'Nee',
		NoAcceptedRequests: 'Geen aanvaarde verzoeken',
		NoEmptyFields: 'Vul lege velden in',
		NoFiltersAvailable: "Geen filters beschikbaar",
		NoOptionsAvailable: 'Geen opties beschikbaar',
		NoPartner: 'Geen partner toegewezen',
		NoPendingRequests: 'Geen lopende verzoeken',
		NoRejectedRequests: 'Geen afgewezen verzoeken',
		NoRoleAssigned: 'Geen rol toegewezen',
		NoTenantAdmins: 'Geen tenantbeheerders',
		NoTenants: 'Geen beheerde tenants',
		NoPartnerUsers: 'Geen partnergebruikers',
		NoSubscriptionsFound: "Geen abonnementen gevonden",
		NoTenantSelected: 'Geen tenant geselecteerd',
		NumberOfUsers: 'Aantal gebruikers',
		OcrSettings: 'OCR-instellingen',
		OcrLanguage: 'OCR-taal',
		Ok: 'Ok',
		OnceOff: 'Eenmalig',
		OpenDocumentsInClientApp: 'Open ondersteunende documenten in client toepassing',
		OriginalSubject: 'Origineel onderwerp',
		OutlookSettings: 'Outlook-instellingen',
		Overview: 'Overzicht',
		OverwriteUserEmailMappings: "Wilt u alle emailmappings van gebruikers overschrijven?",
		OverwriteUsers: "Gebruikers overschrijven",
		LandingPage: "Landingspagina",
		LastActivity: 'Laatste activiteit',
		LastActivityNone: '-',
		LibraryPanelSubtitle: 'Sitebibliotheken toevoegen, verwijderen of bewerken',
		LicenseExpiryDate: 'Licentie vervaldatum',
		LicenseType: 'Licentie type',
		LicenseCount: 'Aantal licenties',
		ListName: 'Lijstnaam',
		LoadingPortal: 'XA dms portal laden...',
		LoadingFilters: "Filters laden",
		Locations: 'Instellingen invoegtoepassingen',
		Paid: 'Betaald',
		Payments: 'Betalingen',
		PaymentMethod: 'Betaalmethode',
		PaymentStatus: 'Betalingsstatus',
		Partner: 'Partner',
		Partners: 'Partners',
		PartnerUsers: 'Partner gebruikers',
		Pending: 'In afwachting',
		PermissionSettings: 'Rechten instellingen',
		PIISettings: 'PII-instellingen',
		PickColor: 'Kleur kiezen',
		Profile: 'Profiel',
		PricePerLicenseMonth: "Maandelijks - €{price}/gebruiker/maand",
		PricePerLicenseYear: "Jaarlijks - €{price}/gebruiker/maand",
		Received: 'Ontvangen',
		References: 'Referenties',
		Regenerate: 'Regenereren',
		RegenerateApiKeyWarning: 'Weet u zeker dat u een nieuwe API sleutel wilt genereren? Deze actie is onomkeerbaar.',
		Remove: 'Verwijderen',
		Rejected: 'Afgekeurd',
		ReplyTo: 'Antwoord aan',
		RequiredMetadataOnPublish: 'Vereiste metadata bij publicatie',
		Requests: 'Verzoeken',
		RequestDate: 'Aanvraagdatum',
		RequestTenant: 'Verzoek tenant',
		RequestingPartner: 'Verzoekende partner',
		Reset: "Annuleren",
		RestrictToLibraries: 'Beperken tot bibliotheek/lijst(en) bijv. lijst, lijst2',
		RestrictToSites: 'Beperken tot site(s) bijv. site1, site2',
		Resume: 'Hervatten',
		RevokeLicenses: 'Licenties intrekken',
		Role: 'Rol',
		Save: 'Opslaan',
		SaveChanges: 'Wijzigingen opslaan',
		SaveOnSend: 'Opslaan na sturen',
		SaveRequest: 'Verzoek opslaan',
		Search: 'Zoeken',
		SearchSectionFilter: "Zoek {section} filters",
		SelectDatasourceField: 'Selecteer gegevensbronveld',
		SelectDatasourceType: 'Selecteer een gegevensbrontype',
		SelectPartner: 'Selecteer een partner om deze tenant te beheren',
		SelectTenantAdmin: 'Selecteer tenantbeheerders',
		SelectTenant: 'Selecteer tenant',
		SetAsPartner: 'Stel de gebruiker in als partner',
		SelectPiiInfoTypes: 'Selecteer PII-infotypes',
		SelectSpecificDates: "Specifieke datums selecteren",
		SelectSubscription: "Selecteer abonnement",
		SelectPaymentMethod: 'Selecteer betaalmethode',
		SetAsTenantAdmin: 'Stel de gebruiker in als tenantbeheerder',
		SignInDifferentAccount: 'Login met een ander account',
		SignInRequest: 'Het inlogverzoek verwerken...',
		SignedOutMessage: 'U heeft zich met succes afgemeld bij de XA dms portal-applicatie.',
		SignOut: 'Afmelden',
		Site: 'site',
		Sites: 'sites',
		SitesLibrariesConfigured: '{siteCount} sites, {libraryCount} bibliotheken geconfigureerd',
		SiteName: 'Site naam',
		SiteSettings: 'Site-instellingen',
		SiteUrl: 'Site-URL',
		SharepointColumnName: 'SharePoint-kolomnaam(-namen) bijv. col1, col2',
		Show: 'Toon',
		ShowDateRangeSlider: "Toon datumbereik schuif",
		ShowLess: "Laat minder zien",
		ShowMore: "Laat meer zien",
		ShowAllFields: 'Toon alle velden',
		ShowAllTeams: 'Toon alle teams',
		ShowAllSites: 'Toon alle sites',
		ShowAllMetadata: 'Toon alle metadata velden',
		ShowAllTemplates: 'Toon alle sjablonen',
		ShowAll: 'Toon alles',
		ShowSelected: 'Geselecteerde tonen',
		Status: 'Status',
		Subject: 'Onderwerp',
		Subscription: 'Abonnement',
		Subscriptions: 'Abonnementen',
		SubscriptionTier: 'Abonnementsniveau',
		SubscriptionType: 'Type abonnement',
		Success: 'Succes',
		Suspend: 'Opschorten',
		TeamName: 'Teamnaam',
		TeamSettings: 'Teams-instellingen',
		Tenant: 'Tenant',
		Tenants: 'Tenants',
		TenantAdmin: 'Tenantbeheerder',
		TenantId: 'Tenant ID',
		TenantRequestSuccess: 'Met succes aangevraagd om een tenant te beheren',
		Title: 'Titel',
		TitleRequired: 'Titel vereist',
		To: 'Aan',
		TotalRegistrations: 'Totaal aantal inschrijvingen',
		TryAgain: 'Probeer het opnieuw',
		Type: 'Type',
		Unassigned: "Niet toegewezen",
		UnauthorizedMessage: 'U heeft geen toegang tot de XA dms portal-applicatie.',
		Url: 'URL',
		User: 'Gebruiker',
		UserActivity: 'Gebruikersactiviteit',
		Username: 'Gebruikersnaam',
		Users: 'Gebruikers',
		VersionSettings: 'Versie-instellingen',
		Yearly: 'Jaarlijks',
		Yes: 'Ja',
	},
};

export const LanguageContext = React.createContext(locales.en);

export class LocalizationModule {
	static getLocaleStrings() {
		const localeLang = navigator.language?.toLowerCase()?.substring(0, 2) ?? '';

		switch (localeLang) {
			case 'en':
				return locales.en;
			case 'nl':
				return locales.nl;
			default:
				return locales.en;
		}
	}
}
