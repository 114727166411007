import { ILabelStyles, FontWeights, FontSizes, mergeStyles, ISearchBoxStyles, getTheme } from "@fluentui/react"
import { CSSProperties } from "react";
const theme = getTheme();
export const headingStyle: Partial<ILabelStyles> = {
    root: {
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.size28,
    }
}

export const subheadingStyle: Partial<ILabelStyles> = {
    root: {
        fontWeight: FontWeights.regular,
        fontSize: FontSizes.xLarge,
    }
}

export const tableContainerStyle: CSSProperties = {
    height: "calc(100vh - 50px - 90px)", //100vh - 50 - 52 -> 100vh - topNavHeight - "ManagedeTenants" Label height
    position: "relative",
}

export const textBox = mergeStyles({
    width: 300,
    marginRight: 20,
});

export const tableHeader = mergeStyles({
    width: 300,
    marginRight: 20,
});

export const divider = mergeStyles({
    margin: 0,
    padding: 0,
    fontSize: 5
});

export const wideDivider = mergeStyles({
    marginTop: 15,
    padding: 0,
});

export const chartTitle: Partial<ILabelStyles> = {
    root: {
        fontWeight: FontWeights.regular,
        fontSize: FontSizes.xLarge,
        marginLeft: 60,
        marginTop: 15,
        marginBottom: 10,
    }
}

export const searchBoxStyles: Partial<ISearchBoxStyles> = {
    root: {
        width: "400px",
        "@media only screen and (max-width: 768px)": {
            width: "350px",
        },
        borderColor: theme.palette.neutralPrimary,
        marginRight: 0,
    }
};

// const searchBox = mergeStyles({
//     "@media only screen and (max-width: 768px)" : {
//         width: "300px"
//     }

// })