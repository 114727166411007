import { ILabelStyles, FontWeights, FontSizes, mergeStyles, ISearchBoxStyles, getTheme } from "@fluentui/react"

const theme = getTheme();
export const headingStyle: Partial<ILabelStyles> = {
    root: {
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.size28,
    }
}

export const subheadingStyle: Partial<ILabelStyles> = {
    root: {
        fontWeight: FontWeights.regular,
        fontSize: FontSizes.xLarge,
    }
}

export const textBox = mergeStyles({
    width: 300,
    marginRight: 20,
});

export const tableHeader = mergeStyles({
    width: 300,
    marginRight: 20,
});

export const divider = mergeStyles({
    margin: 0,
    padding: 0,
    fontSize: 5
});

export const wideDivider = mergeStyles({
    marginTop: 15,
    padding: 0,
});

export const searchBoxStyles: Partial<ISearchBoxStyles> = {
    root: {
        width: "400px",
        borderColor: theme.palette.neutralPrimary,
        marginRight: 20,
    }
};